var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "page-header",
        {
          attrs: {
            "show-back-icon": "",
            title: _vm.page.name + " / " + _vm.$t("Edit page")
          },
          on: { back: _vm.handleBackToPageList }
        },
        [
          _c(
            "template",
            { slot: "extra" },
            [
              _c(
                "a-button",
                {
                  attrs: { icon: "export" },
                  on: { click: _vm.handlePageExport }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Export page")) + " ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "a-card",
        {
          staticStyle: { "margin-top": "12px" },
          attrs: { loading: _vm.loading }
        },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                _vm._b(
                  { attrs: { label: _vm.$t("Name") } },
                  "a-form-item",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["name", _vm.formItemOptions.name],
                        expression: "['name', formItemOptions.name]"
                      }
                    ],
                    attrs: {
                      placeholder: _vm.$t("Please input your page name")
                    },
                    on: {
                      keypress: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                _vm._b(
                  { attrs: { label: _vm.$t("Descriptions") } },
                  "a-form-item",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["description", _vm.formItemOptions.description],
                        expression:
                          "['description', formItemOptions.description]"
                      }
                    ],
                    attrs: {
                      rows: 3,
                      placeholder: _vm.$t("Please input your page description")
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                _vm._b(
                  { attrs: { label: _vm.$t("Photo") } },
                  "a-form-item",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c("UploadPhoto", {
                    attrs: {
                      url: _vm.thumbnail.url,
                      "folder-name-s3": _vm.folderNameS3
                    },
                    on: {
                      upload: function(newURL) {
                        _vm.thumbnail = Object.assign({}, _vm.thumbnail, {
                          url: newURL
                        })
                      },
                      delete: function() {
                        _vm.thumbnail = { url: "", alt: "" }
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                _vm._b(
                  { attrs: { label: _vm.$t("Link") } },
                  "a-form-item",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: {
                        placement: "top",
                        title: _vm.$t("Click to copy")
                      }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "copy-clipboard",
                          on: {
                            click: function($event) {
                              return _vm.copyClipBoard(_vm.pathToPage)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "mr-sm" }, [
                            _vm._v(_vm._s(_vm.pathToPage))
                          ]),
                          _c("a-icon", { attrs: { type: "copy" } })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                _vm._b(
                  { attrs: { label: _vm.$t("Created date") } },
                  "a-form-item",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("dayjs")(_vm.page.created)))
                  ])
                ]
              ),
              _c(
                "a-form-item",
                _vm._b(
                  { attrs: { label: _vm.$t("Updated date") } },
                  "a-form-item",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("dayjs")(_vm.page.modified)))
                  ])
                ]
              ),
              _vm.page.template !== "PORTAL"
                ? _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: _vm.$t("Code list") } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("router-link", { attrs: { to: _vm.pathToCodes } }, [
                        _vm._v(" " + _vm._s(_vm.$t("View code list")) + " ")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.page.template !== "PORTAL"
                ? _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: _vm.$t("Category setting") } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c(
                        "router-link",
                        { attrs: { to: _vm.pathToCategorySetting } },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("View category setting")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                _vm._b(
                  { attrs: { label: _vm.$t("Security") } },
                  "a-form-item",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c("a-switch", {
                    attrs: {
                      checked: _vm.page.ispublic,
                      "checked-children": _vm.$t("Public"),
                      "un-checked-children": _vm.$t("Private"),
                      loading: _vm.publicLoading
                    },
                    on: { change: _vm.handleOpenStatusModal }
                  })
                ],
                1
              ),
              _vm.page.template !== "PORTAL"
                ? _c(
                    "a-form-item",
                    _vm._b(
                      { attrs: { label: "Webhook" } },
                      "a-form-item",
                      _vm.formItemLayout,
                      false
                    ),
                    [
                      _c("a-switch", {
                        attrs: {
                          "checked-children": _vm.$t("Public"),
                          "un-checked-children": _vm.$t("Private")
                        },
                        on: { change: _vm.changeStatusWebhook },
                        model: {
                          value: _vm.webhookStatus,
                          callback: function($$v) {
                            _vm.webhookStatus = $$v
                          },
                          expression: "webhookStatus"
                        }
                      }),
                      _vm.webhookStatus
                        ? _c(
                            "span",
                            { staticStyle: { width: "40%" } },
                            [
                              _c(
                                "a-card",
                                [
                                  _c(
                                    "a-row",
                                    [
                                      _c("a-col", { attrs: { span: "4" } }, [
                                        _c("strong", [_vm._v("Token: ")])
                                      ]),
                                      _c("a-col", { attrs: { span: "8" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            on: {
                                              click: function($event) {
                                                return _vm.copyClipBoard(
                                                  _vm.webhookToken
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.webhookToken) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "a-col",
                                        { attrs: { span: "4" } },
                                        [
                                          _vm.webhookStatus
                                            ? _c("a-icon", {
                                                style: { fontSize: "24px" },
                                                attrs: { type: "reload" },
                                                on: { click: _vm.generateToken }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                _vm._b(
                  { attrs: { label: _vm.$t("Dictionaries") } },
                  "a-form-item",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              width: "100%",
                              "line-height": "normal",
                              color: "rgb(122 122 122)",
                              "margin-bottom": "4px"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "Choosing dictionary will enable customize the pronunciation of words when reading content. Maximum 5 dictionaries can be selected."
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "tree-data": _vm.dictionaryTreeData,
                      "tree-checkable": "",
                      "search-placeholder": _vm.$t("Please select")
                    },
                    model: {
                      value: _vm.dictionary,
                      callback: function($$v) {
                        _vm.dictionary = $$v
                      },
                      expression: "dictionary"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { wrapperCol: { offset: 3, span: 4 }, colon: false } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        loading: _vm.submitLoading,
                        type: "primary",
                        block: ""
                      },
                      on: { click: _vm.handleSubmitForm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Change Page")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.changeStatus.visible
        ? _c("ChangeStatusModal", {
            attrs: {
              visible: _vm.changeStatus.visible,
              page: _vm.page,
              loading: _vm.changeStatus.loading,
              "have-publish-version": _vm.changeStatus.havePublishVersion
            },
            on: {
              cancel: function($event) {
                _vm.changeStatus.visible = false
              },
              ok: _vm.handleChangeStatus,
              onOpenIframeReview: function($event) {
                _vm.iframeVisible = true
              }
            }
          })
        : _vm._e(),
      _vm.iframeVisible
        ? _c(
            "div",
            [
              _vm.iframeVisible && _vm.page.template === "PORTAL"
                ? _c("EditPortalWebsiteRenderer", {
                    ref: "editWebsite",
                    attrs: { visible: _vm.iframeVisible, page: _vm.page },
                    on: {
                      cancel: function($event) {
                        _vm.iframeVisible = false
                      },
                      "publish-new-version": _vm.handleAlertPublishNewVersion
                    }
                  })
                : _c("iframe-review", {
                    ref: "editWebsite",
                    attrs: { visible: _vm.iframeVisible, page: _vm.page },
                    on: {
                      cancel: function($event) {
                        _vm.iframeVisible = false
                      },
                      "publish-new-version": _vm.handleAlertPublishNewVersion
                    }
                  })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }